<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block"/>
            <b-button
                v-if="$store.state.user.planning === true"
                :disabled="$store.state.app.showLoader"
                class="ml-2" variant="danger" @click="unlockOrders">Končaj planiranje
            </b-button>
            <b-button
                v-else
                :disabled="$store.state.app.showLoader"
                @click="lockOrders"
                class="ml-2" variant="success">Začni planiranje
            </b-button>
            <b-spinner class="ml-2" v-if="$store.state.app.showLoader"/>


        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ name }}
                        </p>
                        <span class="user-status">{{ roleTitle }}</span>
                    </div>
                    <!--                    <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require('@/assets/images/avatars/13-small.png')"
              class="badge-minimal"
              badge-variant="success"
          />-->
                </template>

                <b-dropdown-item link-class="d-flex align-items-center" @click="$router.push('profil')">
                    <feather-icon
                        size="16"
                        icon="UserIcon"
                        class="mr-50"
                    />
                    <span>Profil</span>
                </b-dropdown-item>

                <b-dropdown-divider/>

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Odjava</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider
    } from 'bootstrap-vue'
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import useJwt from '@/auth/jwt/useJwt'
    //import {initialAbility} from '@/libs/acl/config'

    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,

            // Navbar Components
            DarkToggler
        },
        data() {
            return {
                name: '',
                roleId: '',
                roleTitle: ''
            }
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {
                }
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('user/logOut')
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                sessionStorage.removeItem('userData')
                //this.$ability.update(initialAbility)
                this.$router.push('login')
            },
            getRoleTitle() {
                const thisIns = this
                this.$http.get(`/api/v1/management/role/${thisIns.roleId}`)
                    .then(function(response) {
                        thisIns.roleTitle = response.data.title
                    })
                    .catch(function() {
                        thisIns.$printWarning('Napaka!')
                    })
            },
            async lockOrders() {
                this.$store.state.app.showLoader = true
                const thisIns = this
                this.$http.post('/api/v1/user/lock_all_orders_in_bc')
                    .then(function(response) {
                        thisIns.$printSuccess(response.data.status)
                        thisIns.$store.dispatch('user/setPlanning', true)
                    })
                    .catch(function() {
                        thisIns.$printWarning('Napaka!')
                    }).finally(() => {
                        thisIns.$store.state.app.showLoader = false
                    })

            },
            async unlockOrders() {
                this.$store.state.app.showLoader = true
                const thisIns = this
              
                this.$http.post('/api/v1/user/unlock_all_locked_orders_in_bc')
                    .then(function(response) {
                        thisIns.$printSuccess(response.data.status)
                        thisIns.$store.dispatch('user/setPlanning', false)
                    })
                    .catch(function() {
                        thisIns.$printWarning('Napaka!')
                    }).finally(() => {
                        thisIns.$store.state.app.showLoader = false
                    })
            },
            updatePlanningStatus() {
                const thisIns = this
                thisIns.$store.state.app.showLoader = true
                this.$http.get('/api/v1/user/lock_status')
                    .then(function(response) {
                        thisIns.$store.dispatch('user/setPlanning', response.data)
                    })
                    .catch(function() {
                        thisIns.$printWarning('Napaka!')
                    }).finally(() => {
                        thisIns.$store.state.app.showLoader = false
                    })
            }
        },
        created() {
            this.roleId = JSON.parse(sessionStorage.getItem('userData')).role.id
            this.getRoleTitle()
            this.name = JSON.parse(sessionStorage.getItem('userData')).name
            this.updatePlanningStatus()
        }
    }
</script>
