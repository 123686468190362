import permissions from '../../permissions/index.js'

export default [
    /*{
      title: 'general.home',
      route: 'home',
      icon: 'HomeIcon'
      // required_permission: ['admin']
      // če dodaš array required_permission, se bodo na levi strani v navbaru izrisovali samo routi,
      // z dovoljenimi permissioni
      // koda ki to dejansko preverja se nahaja v:
      // src\@core\layouts\layout-vertical\components\vertical-nav-menu\components\vertical-nav-menu-items\VerticalNavMenuItems.vue
    },*/
    {
        title: 'general.dashboard',
        route: 'dash',
        icon: 'BarChartIcon',
        required_permission: [permissions.PermissionGetRole]
    },
    {
        title: 'general.users',
        route: 'users',
        icon: 'UsersIcon',
        required_permission: [permissions.PermissionGetUser]
    },
    {
        title: 'general.vloge',
        route: 'vloge',
        icon: 'UserCheckIcon',
        required_permission: [permissions.PermissionGetRole]
    },
    {
        title: 'general.narocila',
        route: 'narocila',
        icon: 'BriefcaseIcon',
        required_permission: [permissions.PermissionGetOrder]
    },
    {
        title: 'general.cakalna_vrsta',
        route: 'cakalna_vrsta',
        icon: 'ClockIcon',
        required_permission: [permissions.PermissionGetOrder]
    },
    {
        title: 'general.zaloga',
        icon: 'DatabaseIcon',
        required_permission: [permissions.PermissionGetStock],
        children: [
            {
                title: 'general.kosi',
                route: 'zaloga'
            },
            {
                title: 'general.material',
                route: 'zalogaMaterial'
            }
        ]
    },
    {
        title: 'general.plani',
        icon: 'CalendarIcon',
        required_permission: [permissions.PermissionGetCuttingPlan],
        children: [
            {
                title: 'general.vsi',
                route: 'plansAll'
            },
            {
                title: 'general.potrjeni',
                route: 'plansConfirmed'
            },
            {
                title: 'general.odprti',
                route: 'plansOpened'
            }
        ]
    },
    {
        title: 'Statistika',
        route: 'statistika',
        icon: 'BarChartIcon',
        required_permission: [permissions.PermissionGetCuttingPlan]

    },
    {
        title: 'Nastavitve',
        route: 'config',
        icon: 'SettingsIcon',
        required_permission: [permissions.PermissionGetConfig]

    }
]
